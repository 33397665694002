











































import { Component } from "vue-property-decorator";
import { Guid } from 'guid-typescript';

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";
import { SharedModule } from "@/feature/Shared/store";

import { AreaManagerStore } from "./store";
import PgaAreaManagerDetails from "./Components/PgaAreaManagerDetails.vue";
import { AreaManagerModel, EntityOrderStatus } from "@/services/iOrderService";
import { SendEmailToPermissionsCommand } from "@/services/iNotificationService";
import { EmailsStore } from "@/feature/Shared/Email/store";

@Component({
	components:
	{
		PgaAreaManagerDetails,
	},
})
export default class AreaManagersPageDetails extends mixins(SharedMixin)
{
	private showDialog = false;
	private title = "";
	private message = "";

	get areaManagerDetails(): AreaManagerModel
	{
		return AreaManagerStore.AreaManagerDetails;
	}

	get areaManagerCostCenter() : string
	{
		return this.areaManagerDetails?.CostCenter ?? '';
	}

	get isRequestableDeletion(): boolean
	{
		return this.areaManagerDetails.Status !== EntityOrderStatus.DeletionRequest &&
			this.areaManagerDetails.Status !== EntityOrderStatus.Created;
	}

	get isApprovable(): boolean 
	{
		return this.areaManagerDetails?.Status == EntityOrderStatus.Created;
	}

	get isApprovableDeletion(): boolean 
	{
		return this.areaManagerDetails?.Status == EntityOrderStatus.DeletionRequest;
	}

	get isEditable() : boolean
	{
		return this.areaManagerDetails?.Status == EntityOrderStatus.Created;
	}

	async approve(): Promise<void> 
	{
		try {
		await AreaManagerStore.ApproveAreaManager();
		} catch (reason) {
		SharedModule.SET_ALERT_ERROR_MESSAGE(
			`error approving AreaManager: ${reason}`
		);
		SharedModule.SET_ALERT_IS_VISIBLE(true);
		}
	}

	async approveDeletion(): Promise<void> 
	{
		try
		{
			await AreaManagerStore.ApproveDeletionAreaManager();
			this.$router.go(-1);
		}
		catch (reason)
		{
			SharedModule.SET_ALERT_ERROR_MESSAGE(`error approving deletion AreaManager: ${reason}`);
			SharedModule.SET_ALERT_IS_VISIBLE(true);
		}
	}

	ShowDialogDelete(): void
	{
		this.title = "Deleting Cost Center";
		this.message = `Are you sure you want to delete the Cost Center '${this.areaManagerCostCenter}'?`;
		this.showDialog = true;
	}

	ShowDialogRequestDelete(): void
	{
		this.title = "Request Deletion Cost Center";
		this.message = `Are you sure you want to requeste deletion of Cost Center '${this.areaManagerCostCenter}'?`;
		this.showDialog = true;
	}

	HideDialog(): void
	{
		this.showDialog = false;
		this.title = "";
		this.message = "";
	}

	private Edit() : void
	{
		const id: string = this.areaManagerDetails.Id.toString();
		this.$router.push({ name: "areamanageredit", params: { id } });
	}

	private requestChanges()
	{
		const sendEmailCommand : SendEmailToPermissionsCommand = 
		{
			PermissionIds: ["62418EF6-A061-4718-8BC5-B5A00C307E08"],
			Subject: `Request changes for AreaManager of ${this.areaManagerDetails.CostCenter}`,
			Body: "",
		};
		EmailsStore.SET_SEND_EMAIL_TO_PERMISSIONS_COMMAND(sendEmailCommand);
		EmailsStore.SET_SHOW_FORM_EMAIL_TO_PERMISSIONS(true);
	}

	async drop(): Promise<void>
	{
		if (this.areaManagerDetails)
			await AreaManagerStore.DeleteAreaManager(this.areaManagerDetails.Id);

		this.showDialog = false;
		this.$router.go(-1);
	}	

	async mounted(): Promise<void>
	{
		AreaManagerStore.DropAreaManagerDetails();

		const id: string = this.$route.params.id;
		await AreaManagerStore.GetAreaManagerDetails(Guid.parse(id));
	}
}
