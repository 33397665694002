


































import { Component, Model } from "vue-property-decorator";
import { Guid } from 'guid-typescript';

import { AreaManagerStore }  from '../store';
import { EditAreaManagerCommand } from '@/services/iOrderService';
import { UserSimple } from '@/services/iIdentityService';

import { mixins } from "vue-class-component";
import SharedMixin from '@/mixins/SharedMixin';

@Component({})
export default class PgaAreaManagerEdit extends mixins(SharedMixin)
{
	@Model("", { type: Object }) readonly Model!: EditAreaManagerCommand;

	get costCenter() : string
	{
		return this.Model?.CostCenter ?? "";
	}
	set costCenter(costCenter: string)
	{
		AreaManagerStore.SET_AREAMANAGER_EDIT_COSTCENTER(costCenter);
	}

	get userId() : Guid
	{
		return this.Model?.UserId ?? Guid.createEmpty();
	}
	set userId(userId: Guid)
	{
		AreaManagerStore.SET_AREAMANAGER_EDIT_USER_ID(userId);
	}

	get Errors(): Map<string, Array<string>>
	{
		return AreaManagerStore.AreaManagerEdit.Errors;
	}

	get userDropDown() : Array<UserSimple>
	{
		return AreaManagerStore.UsersDropDown;
	}
}
