


















import { Component, Prop } from "vue-property-decorator";
import { AreaManagerModel } from "@/services/iOrderService";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

@Component({
	components: { },
})
export default class PgaAreaManagersTable extends mixins(SharedMixin)
{
	@Prop({ default: function() { return new Array<AreaManagerModel>() } }) readonly AreaManagerList!: Array<AreaManagerModel>;
    @Prop({ default: true }) readonly isLoading!: boolean;

	headers: Array<unknown> = [
		{
			text: "Area",
			align: "start",
			sortable: true,
			value: "CostCenter",
			width: "20%",
			keySearch: "cc",
		},
		{
			text: "Name",
			align: "start",
			sortable: true,
			value: "User",
			keySearch: "us",
		},
		{
			text: "Status",
			align: "start",
			value: "Status",
			width: "10%",
		},
	];

	rowClicked(item: any): void {
		const id = item.Id;
		this.$router.push({ name: "areamanagerdetails", params: { id } });
	}
}
