














import { Component } from "vue-property-decorator";
import { AreaManagerStore, CreateAreaManagerModel } from "./store";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import PgaAreaManagerCreate from "./Components/PgaAreaManagerCreate.vue";

@Component({
	components:
	{
		PgaAreaManagerCreate,
	},
})
export default class AreaManagersPageCreate extends mixins(SharedMixin)
{

	get areaManagerCreate() : CreateAreaManagerModel
	{
		return AreaManagerStore.AreaManagerCreate;
	}

	async Create() : Promise<void>
	{
		try
		{
			const id: string = (await AreaManagerStore.CreateAreaManager()).Id.toString();
			this.$router.replace({ name: "areamanagerdetails", params: { id } });
		}
		// eslint-disable-next-line no-empty
		catch (error) { }
	}

	undo() : void
	{
		this.$router.go(-1);
	}

	mounted() : void
	{
		AreaManagerStore.InitAreaManagerCreate();
		AreaManagerStore.GetUsersDropDown();
	}

	beforeDestroy() : void
	{
		AreaManagerStore.DropAreaManagerCreate();
		AreaManagerStore.AreaManagerCreate.Errors = new Map<string, Array<string>>();
	}
}
