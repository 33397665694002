
























import { Component, Model } from "vue-property-decorator";
import { AreaManagerModel } from "@/services/iOrderService";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";
import { EntityOrderStatus } from "@/services/iOrderService";

@Component({
	components: { }
})
export default class PgaAreaManagerDetails extends mixins(SharedMixin) {
	@Model("", { type: Object }) readonly Model!: AreaManagerModel;

	get costCenter(): string
	{
		return this.Model?.CostCenter ?? "";
	}

	get fullName(): string
	{
		return this.Model?.User.FullName ?? "";
	}

	get status(): EntityOrderStatus
	{
		return this.Model?.Status;
	}
}
