














import { Component } from "vue-property-decorator";
import { AreaManagerStore, EditAreaManagerModel } from "./store";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import PgaAreaManagerEdit from "./Components/PgaAreaManagerEdit.vue";

@Component({
	components:
	{
		PgaAreaManagerEdit,
	},
})
export default class AreaManagersPageEdit extends mixins(SharedMixin)
{

	get areaManagerEdit() : EditAreaManagerModel
	{
		return AreaManagerStore.AreaManagerEdit;
	}

	async Edit() : Promise<void>
	{
		try
		{
			await AreaManagerStore.EditAreaManager();
			this.$router.go(-1);
		}
		// eslint-disable-next-line no-empty
		catch (error) { }
	}

	undo() : void
	{
		this.$router.go(-1);
	}

	mounted() : void
	{
		AreaManagerStore.GetUsersDropDown();
		AreaManagerStore.GetAreaManagerToEdit();
	}

	beforeDestroy() : void
	{
		AreaManagerStore.DropAreaManagerEdit();
		AreaManagerStore.AreaManagerEdit.Errors = new Map<string, Array<string>>();
	}
}
