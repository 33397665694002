import { VuexModule, Module, Mutation, getModule, Action } from 'vuex-module-decorators'
import store from '@/infrastructure/store'
import { Guid } from 'guid-typescript';

import pgaDiContainer from '@/App.container';
import { SharedModule } from '@/feature/Shared/store';

import OrderService from '@/services/OrderService';

import iIdentityService, { UserSimple } from '@/services/iIdentityService';
import IdentityService from '@/services/IdentityService';

import IOrderService from '@/services/iOrderService';
import { ListAreaManagers, AreaManagerModel, User, EntityOrderStatus,
        CreateAreaManagerCommand, EditAreaManagerCommand,
        CreateAreaManagerResponse, DeleteAreaManagerResponse, EditAreaManagerResponse } from '@/services/iOrderService';

export interface AreaManagerState
{
    ListAreaManagers: ListAreaManagers;
    AreaManagerDetails: AreaManagerModel | null;
    AreaManagerCreate: CreateAreaManagerModel;
    AreaManagerEdit: EditAreaManagerModel;
    AreaManagerDelete: DeleteAreaManagerModel;

    UsersDropDown: Array<UserSimple>;
}

export interface CreateAreaManagerModel
{
    Command: CreateAreaManagerCommand | null;
    Response: CreateAreaManagerResponse | null;
    Errors: Map<string, Array<string>>;
}

export interface EditAreaManagerModel
{
    Command: EditAreaManagerCommand | null;
    Response: EditAreaManagerResponse | null;
    Errors: Map<string, Array<string>>;
}

export interface DeleteAreaManagerModel
{
    Id: Guid;
    Response: DeleteAreaManagerResponse | null;
}

@Module({ namespaced: true, dynamic: true, store, name: 'AreaManagerModule', })
class AreaManagersStore extends VuexModule implements AreaManagerState
{
    private orderService: IOrderService = pgaDiContainer.get<IOrderService>(OrderService);
    private identityService: iIdentityService = pgaDiContainer.get<iIdentityService>(IdentityService);


    public UsersDropDown: Array<UserSimple> = new Array<UserSimple>();


    public ListAreaManagers: ListAreaManagers = 
    {
        AreaManagers: new Array<AreaManagerModel>(),
    };

    public AreaManagerDetails: AreaManagerModel = 
    {
        Id: Guid.createEmpty(),
        CostCenter: "",
        User:
        {
            Id: Guid.createEmpty(),
            FullName: "",
        },
        Status: EntityOrderStatus.Created,
        TenantId: Guid.createEmpty(),
    };

    public AreaManagerCreate: CreateAreaManagerModel = 
    {
        Command: null,
        Response: null,
        Errors: new Map<string, Array<string>>(),
    };

    public AreaManagerEdit: EditAreaManagerModel =
    {
        Command: null,
        Response: null,
        Errors: new Map<string, Array<string>>(),
    }

    public AreaManagerDelete: DeleteAreaManagerModel = 
    {
        Id: Guid.createEmpty(),
        Response: null,
    };


    // AREA MANAGER TABLE
    @Action({ rawError: true })
    public async ListAreaManagersAPI(): Promise<void>
    {
        const ccams: ListAreaManagers = await this.orderService.GetAllAreaManagers();
        this.SET_AREAMANAGERS_TABLE(ccams);
    }
    @Mutation
    public SET_AREAMANAGERS_TABLE(ccams: ListAreaManagers)
    {
        this.ListAreaManagers = ccams
    }

    // AREA MANAGER DETAILS
    @Action({ rawError: true })
    public async GetAreaManagerDetails(Id: Guid): Promise<void> {
        SharedModule.SET_ISLOADING(true);
        const ccam: AreaManagerModel = await this.orderService.GetAreaManagerById(Id);

        this.SET_AREAMANAGER_DETAILS(ccam);
        SharedModule.SET_ISLOADING(false);
    }
    @Action({ rawError: true })
    public DropAreaManagerDetails() {
        this.SET_AREAMANAGER_DETAILS_EMPTY();
    }
    @Mutation
    public SET_AREAMANAGER_DETAILS(AreaManagerDetails: AreaManagerModel) {
        this.AreaManagerDetails = AreaManagerDetails;
    }
    @Mutation
    public SET_AREAMANAGER_DETAILS_EMPTY() {
        this.AreaManagerDetails = 
        {
            Id: Guid.createEmpty(),
            CostCenter: "",
            User:
            {
                Id: Guid.createEmpty(),
                FullName: "",    
            },
            Status: EntityOrderStatus.Created,
            TenantId: Guid.createEmpty(),
        };
    }
    @Mutation
    public SET_AREAMANAGER_DETAILS_ID(Id: Guid) {
        this.AreaManagerDetails.Id = Id;
    }
    @Mutation
    public SET_AREAMANAGER_DETAILS_COSTCENTER(CostCenter: string) {
        this.AreaManagerDetails.CostCenter = CostCenter;
    }
    @Mutation
    public SET_AREAMANAGER_DETAILS_USER(User: User) {
        this.AreaManagerDetails.User = User;
    }
    @Mutation
    public SET_AREAMANAGER_DETAILS_STATUS(Status: EntityOrderStatus) {
        this.AreaManagerDetails.Status = Status;
    }


    // CREATE AREAMANAGER
    @Action({ rawError: true })
    public async CreateAreaManager(): Promise<CreateAreaManagerResponse> {
        try {
            SharedModule.SET_ISLOADING(true);
            if (this.AreaManagerCreate.Command)
                return await this.orderService.CreateAreaManager(this.AreaManagerCreate.Command);
            return { Id: Guid.createEmpty() };
        }
        catch (error) {
            const errs = parseErrors(error);
            this.SET_RESPONSE_CREATE(errs);

            throw error
        }
        finally {
            SharedModule.SET_ISLOADING(false);
        }
    }
    @Action({ rawError: true })
    public async GetUsersDropDown(): Promise<void> {
        const usersDropDown: Array<UserSimple> = await this.identityService.GetAllHADAndHI();        
        this.SET_USERS_DROPDOWN(usersDropDown);
    }
    @Action({ rawError: true })
    public InitAreaManagerCreate() {
        const cmd: CreateAreaManagerCommand =
        {
            CostCenter: '',
            UserId: null,
        }

        this.SET_AREAMANAGER_CREATE(cmd);
    }
    @Action({ rawError: true })
    public DropAreaManagerCreate() {
        this.SET_AREAMANAGER_CREATE(null);
    }
    @Mutation
    public SET_AREAMANAGER_CREATE(areaManagerCreate: CreateAreaManagerCommand | null) {
        this.AreaManagerCreate.Command = areaManagerCreate;
    }
    @Mutation
    public SET_AREAMANAGER_CREATE_COSTCENTER(CostCenter: string) {
        if (this.AreaManagerCreate.Command)
            this.AreaManagerCreate.Command.CostCenter = CostCenter;
    }
    @Mutation
    public SET_AREAMANAGER_CREATE_USER(UserId: Guid) {
        if (this.AreaManagerCreate.Command)
            this.AreaManagerCreate.Command.UserId = UserId;
    }
    @Mutation
    public SET_RESPONSE_CREATE(errors: Map<string, Array<string>>) {
        this.AreaManagerCreate.Errors = errors;
    }
    @Mutation
    public SET_USERS_DROPDOWN(UsersDropDown: Array<UserSimple>) {
        this.UsersDropDown = UsersDropDown;
    }

    // EDIT AREAMANAGER
    @Action({ rawError: true })
    public async EditAreaManager() : Promise<EditAreaManagerResponse>
    {
        try {
            let response = { Id: Guid.createEmpty() };
            SharedModule.SET_ISLOADING(true);
            if (this.AreaManagerEdit.Command)
                response = await this.orderService.EditAreaManager(this.AreaManagerEdit.Command.Id, this.AreaManagerEdit.Command);
            SharedModule.SET_ISLOADING(false);
            return response;
        }
        catch (error) {
            SharedModule.SET_ISLOADING(false);
            const errs = parseErrors(error);
            this.SET_AREAMANAGER_EDIT_ERRORS(errs);
            throw errs;
        }
    }
    @Action({ rawError: true })
    public async GetAreaManagerToEdit() {
        SharedModule.SET_ISLOADING(true);
        const am: AreaManagerModel = await this.orderService.GetAreaManagerById(this.AreaManagerDetails.Id);

        const editAreaManagerCommand: EditAreaManagerCommand = 
        {
            Id: am.Id,
            CostCenter: am.CostCenter,
            UserId: am.User.Id,
        }

        this.SET_AREAMANAGER_EDIT(editAreaManagerCommand);
        SharedModule.SET_ISLOADING(false);
    }
    @Action({ rawError: true })
    public DropAreaManagerEdit()
    {
        this.SET_AREAMANAGER_EDIT(null);
        this.SET_AREAMANAGER_EDIT_ERRORS(new Map<string, Array<string>>());
    }
    @Mutation
    public SET_AREAMANAGER_EDIT(economicLimit: EditAreaManagerCommand | null)
    {
        this.AreaManagerEdit.Command = economicLimit;
    }
    @Mutation
    public SET_AREAMANAGER_EDIT_ID(id: Guid)
    {
        if (this.AreaManagerEdit.Command)
            this.AreaManagerEdit.Command.Id = id;
    }
    @Mutation
    public SET_AREAMANAGER_EDIT_COSTCENTER(costCenter: string) 
    {
        if (this.AreaManagerEdit.Command)
            this.AreaManagerEdit.Command.CostCenter = costCenter;
    }
    @Mutation
    public SET_AREAMANAGER_EDIT_USER_ID(userId: Guid)
    {
        if (this.AreaManagerEdit.Command)
            this.AreaManagerEdit.Command.UserId = userId;
    }
    @Mutation
    public SET_AREAMANAGER_EDIT_ERRORS(errors: Map<string, Array<string>>) {
        this.AreaManagerEdit.Errors = errors;
    }

    // DELETE AREMANAGER
    @Action({ rawError: true })
    public async DeleteAreaManager(id: Guid): Promise<DeleteAreaManagerResponse> {
        try {
            SharedModule.SET_ISLOADING(true);
            this.SET_AREAMANAGER_DELETE(id);

            if (this.AreaManagerDelete)
                return await this.orderService.DeleteAreaManager(this.AreaManagerDelete.Id);
            return { Id: Guid.createEmpty() };
        }
        finally {
            SharedModule.SET_ISLOADING(false);
        }
    }
    @Mutation
    public SET_AREAMANAGER_DELETE(id: Guid) {
        this.AreaManagerDelete.Id = id;
    }

    // APPROVE
    @Action({ rawError: true })
    public async ApproveAreaManager(): Promise<void> {
        SharedModule.SET_ISLOADING(true);
        try {
            if (this.AreaManagerDetails == null)
                return Promise.reject("Details AreaManager not initialized, try reload the application");

            const am = this.AreaManagerDetails;
            const cmd = { Id: am.Id };
            await this.orderService.ApproveAreaManager(cmd);

            this.SET_AREAMANAGER_DETAILS_STATUS(EntityOrderStatus.Approved);
        } finally {
            SharedModule.SET_ISLOADING(false);
        }
    }

    // APPROVE DELETION
    @Action({ rawError: true })
    public async ApproveDeletionAreaManager(): Promise<void> {
        SharedModule.SET_ISLOADING(true);
        try {
            if (this.AreaManagerDetails == null)
                return Promise.reject("Details AreaManager not initialized, try reload the application");

            const am = this.AreaManagerDetails;
            const cmd = { Id: am.Id };
            await this.orderService.ApproveDeletionAreaManager(cmd);

            this.SET_AREAMANAGER_DETAILS_STATUS(EntityOrderStatus.Deleted);
        } finally {
            SharedModule.SET_ISLOADING(false);
        }
    }
}

function parseErrors(error: any): Map<string, Array<string>> {
    const errs = new Map<string, Array<string>>();

    for (const [key, value] of Object.entries(error.response.data)) {
        const messages = value as Array<string>;
        errs.set(key, messages);
    }

    return errs;
}

export const AreaManagerStore = getModule(AreaManagersStore);