













import { Component } from "vue-property-decorator"

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import PgaAreaManagersTable from './Components/PgaAreaManagersTable.vue';
import { AreaManagerStore } from './store';
import { ListAreaManagers } from '@/services/iOrderService';


@Component({
	components:
	{
		PgaAreaManagersTable,
	}
})
export default class AreaManagersIndex extends mixins(SharedMixin)
{
	private isLoading = true;

	get areaManagers(): ListAreaManagers
	{
		return AreaManagerStore.ListAreaManagers;
	}

	async mounted(): Promise<void>
	{
		this.isLoading = true;
		await AreaManagerStore.ListAreaManagersAPI();
		this.isLoading = false;
	}
}
